<template>
  <v-container class="home">
    <v-card elevation="2" color="#fdfdfd" class="mb-4">
      <div
        class="pt-6 text-h6 text-center font-weight-bold"
        style="color:#c75213"
      >
        本平台僅提供疫苗預約服務，完成預約後仍會現場查驗接種資格，
        若現場查驗不符合資格者或接種間隔者，將無法接種。
      </div>
      <v-card-title>接種疫苗</v-card-title>

      <v-card-text class="list">
        <div class="text-subtitle-1">新冠JN.1與流感疫苗</div>
        <ul>
          <li>
            •
            自114年1月1日起開放公費流感疫苗全民接種、12歲以上民眾可選擇接種Novavax
            JN.1 (已完成莫德納 JN.1疫苗接種者，則無需再次預約Novavax JN.1)。
          </li>
          <li>
            新冠疫苗施打種類與對象
            <ol>
              <li>
                第一階段(今年10月1日起)
                <ol>
                  <li>滿6個月以上至國小入學前幼兒</li>
                  <li>國小、國中、高中、高職、五專1-3年級學生</li>
                  <li>65歲以上長者</li>
                  <li>
                    具有潛在疾病，符合下列條件之一者(包括(19-64歲)
                    高風險慢性病人成人、BMI≧30、罕見疾病患者及重大傷病患者者)
                  </li>
                  <li>孕婦及6個月內嬰兒之父母</li>
                  <li>
                    幼兒園托育人員、托育機構專業人員及居家托育人員（保母）
                  </li>
                  <li>安養、長期照顧（服務）等機構之受照顧者及所屬工作人員</li>
                  <li>醫事及衛生防疫相關人員</li>
                  <li>禽畜相關及動物防疫相關人員</li>
                  <li>55歲以上原住民</li>
                </ol>
              </li>
              <li>
                第二階段(今年11月1日起)
                <ol>
                  <li>50歲以上成人(流感疫苗)</li>
                  <li>6個月以上民眾(新冠JN.1)</li>
                </ol>
              </li>
            </ol>
          </li>
          <li>第三階端(114年1月1日起)：流感疫苗開放全民接種。</li>
        </ul>
      </v-card-text>
      <v-card-text class="list">
        <div class="text-subtitle-1">肺炎鏈球菌疫苗</div>
        <ol>
          <li>
            65歲以上長者：一生可接種一劑23價與13價肺炎鏈球菌疫苗，需先接種13價後，間隔1年後再接種23價肺炎鏈球菌疫苗。
          </li>
          <li>
            洗腎、機構、高風險對象者接種一劑13價肺炎鏈球菌疫苗後間隔8周即可接種23價肺炎鏈球菌疫苗，惟該些對象須提供佐證證明，故暫不開放線上預約，若需接種請洽各區衛生所及合約院所。
          </li>
        </ol>
      </v-card-text>
      <v-card-text class="list">
        <div class="text-subtitle-1">M痘疫苗</div>
        <ol>
          <li>
            提供預約對象-(第1劑&第2劑)
            <ol>
              <li>
                近1年內曾有高風險性行為者，例如：多重性伴侶、性交易服務者、於營業場所發生性行為者等；過去曾罹患性病；性接觸對象有上述任一情形者。
              </li>
              <li>
                與確診M痘個案曾有任何形式性接觸之高風險接觸者，但未施打過疫苗
              </li>
            </ol>
          </li>
          <li>
            接種間隔：自112年5月16日起，若曾接種過1劑M痘疫苗，至少間隔4週以上，即可接種第2劑M痘疫苗。建議完整接種2劑，完善保護力。
          </li>
          <li>
            自費接種對象請至配合旅遊醫學門診評估自費接種(國立成功大學醫學院附設醫院)。
          </li>
        </ol>
      </v-card-text>
      <v-card-text class="list">
        <div class="text-subtitle-1">接種注意事項</div>
        <ol>
          <li>
            預約接種當天，若因颱風等天災因素，本市宣布停班停課，請已成功預約接種的民眾，另行預約其他時程，或擇日前往院所、衛生所設站時間隨到隨打(視疫苗量不足者優先給預約者，若疫苗不足則則無法接種)，造成不便，敬請見諒。
          </li>
          <li>
            自112年5月1日起，自本市合約院所接種 COVID-19 疫苗，將收取掛號費。
          </li>
          <li>
            請確認您符合本次接種資格及與前劑接種間隔。前往接種請佩戴口罩，並攜帶健保卡、中華民國身分證／中華民國有效居留證等相關身分證明文件、建議亦可攜帶疫苗接種記錄卡(小黃卡)。5歲以下族群請攜帶兒童健康手冊
          </li>
          <li>
            自113年10月1日起，新冠JN.1疫苗接種後不再提供疫苗接種記錄卡(小黃卡)，若有疫苗接種證明需求請洽地方衛生所申請。
          </li>
          <li>對於疫苗接種有任何疑問，可電洽轄區衛生所為您服務。</li>
        </ol>
      </v-card-text>
      <v-card-text class="list">
        <div class="text-subtitle-1">預約注意事項</div>
        <ol>
          <li>
            為讓特約醫事機構預留準備時間及核對名單，每場次最後可預約時間為接種日前
            1 日中午 12 點，之後將不開放預約與取消，僅供查詢。(舉例：2022/1/1
            場次，僅開放預約和取消至 2021/12/31 中午 12
            點)若您錯過上述取消預約時間，須待該場次結束後，使得再次使用本系統。
          </li>
          <li>若未誠實填寫，本系統和臺南市政府衛生局得取消其預約資格。</li>
          <li>
            您在疫苗接種完畢的 48 小時內，都無法再次使用本次進行下次疫苗預約。
          </li>
          <li>
            長照機構住民符合接種資格者，將由長照機構將自行造冊，再由衛生局安排衛生所或醫療院所到機構接種，不需要自行預約。
          </li>
          <li>
            學生規劃於校內集中接種，暫不提供流感疫苗預約服務，有接種需求可至合約院所或衛生所隨到隨打。
          </li>
          <li>
            本系統僅提供第一劑流感疫苗預約，如有第二劑接種需求請直接至合約醫療院所或衛生所洽詢。
          </li>
          <li>
            若您想快速查看所有場次，歡迎使用流感預約狀況查詢。（<a
              href="https://health-reservation.tainan.gov.tw/query"
              target="_blank"
              >https://health-reservation.tainan.gov.tw/query</a
            >）
          </li>
          <li>
            國外接種WHO核發EUL之COVID-19疫苗，可攜帶個人身分證明文件、原始疫苗接種證明，至就近衛生所完成國外接種紀錄補登錄，以利後續劑次接種條件判斷(但不納入COVID-19疫苗接種紀錄或證明)。
          </li>
          <li>
            本系統乃提供疫苗公費對象預約疫苗接種，惟至現場後仍須遵照護理人員指示協助確認是否符合公費身分，實際接種資格以現場護理人員確認為主。
          </li>
        </ol>
      </v-card-text>
      <v-card-text class="list">
        <div class="text-subtitle-1">
          外縣市暨外籍民眾、111年7月1日以後出生嬰幼童使用本系統須知
        </div>
        <ul>
          <li>
            設籍於外縣市或他國之民眾、以及本市6個月嬰幼童若欲使用本系統預約，請先填寫表單
            (<a href="https://p.tainan.gov.tw/u/x7tp" target="_blank"
              >https://p.tainan.gov.tw/u/x7tp</a
            >) 進行申請。
          </li>
          <li>
            臺南市政府將於每日上午 9 點將過去 24
            小時申請之名單更新至系統，更新成功之外縣市及外籍民眾得於每日上午 10
            點後開始使用本系統。 (舉例: 2022/01/24 上午 7
            點填寫完畢者，2022/01/24 上午 10 點後即可開始使用預約系統；
            2022/01/24 上午 9 點 30 分填寫完畢者，2022/01/25 上午 10
            點後方可開始使用預約系統)
          </li>
          <li>
            若因為民眾自行填寫資料錯誤，致使無法使用系統或更新失敗，臺南市政府不負任何責任，請再次填寫表單。
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <v-card elevation="2" color="#fdfdfd">
      <v-card-title>接種預約</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            outlined
            class="text-left"
            v-model="form.id"
            :rules="[
              (v) => !!v || `身分證字號為必填項目`,
              (v) =>
                /^[A-Za-z0-9]{10}$/.test(v) ||
                `身分證字號格式錯誤，含英文共 10 碼`,
            ]"
            validate-on-blur
            persistent-hint
            hint="含英文共 10 碼"
            label="身分證字號"
            required
            clearable
            dense
          ></v-text-field>

          <v-text-field
            outlined
            class="text-left mt-2"
            v-model="form.birth"
            :rules="[
              (v) => !!v || `出生年月日為必填項目`,
              (v) =>
                /^\d?\d{6}$/.test(v) ||
                `出生年月日格式錯誤，民國生日 7 碼，例如 35 年 04 月 11 日，請輸入 0350411`,
            ]"
            validate-on-blur
            label="出生年月日"
            persistent-hint
            hint="民國生日 7 碼，例如 35 年 04 月 11 日，請輸入 0350411"
            required
            clearable
            dense
          ></v-text-field>

          <v-text-field
            outlined
            class="text-left mt-2"
            v-model="form.tel"
            :rules="[
              (v) => !!v || `手機號碼為必填項目`,
              (v) =>
                /^09\d{8}$/.test(v) ||
                `手機號碼格式錯誤，僅接受手機門號共 10 碼`,
            ]"
            validate-on-blur
            label="手機號碼"
            persistent-hint
            hint="僅接受手機門號共 10 碼"
            required
            clearable
            dense
          ></v-text-field>
          <v-checkbox
            class="mt-2"
            v-model="checkbox"
            :rules="[(v) => v]"
            label="我已詳細閱讀預約資格與注意事項"
          ></v-checkbox>
          <v-row class="">
            <v-col>
              <v-btn color="success" @click="validate('reserve')" block x-large>
                <v-icon>mdi-calendar-check</v-icon> 接種預約
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="warning" @click="validate('check')" block x-large>
                <v-icon>mdi-calendar-refresh</v-icon> 查詢/取消預約
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ls from "expired-localstorage";
import axios from "axios";
import dayjs from "dayjs";
import { vaccines } from "@/libs/const.js";
const stop = false;

const expired = 24 * 60 * 60 * 1000;

export default {
  name: "Home",
  data: () => ({
    valid: true,
    checkbox: false,
    form: {
      id: "",
      birth: "",
      tel: "",
    },
  }),
  created() {
    this.form = ls.get("form") || this.form;
  },
  methods: {
    // toQuery() {
    //   window.open("https://vaccine-query.tainan.gov.tw/", "_blank");
    // },

    getPastDate(day) {
      return dayjs()
        .add(day * -1, "days")
        .format("YYYY年MM月DD日");
    },
    async validate(action) {
      if (stop) {
        alert("目前網站維護中，請稍後再試！");
        return;
      }

      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.form.id = this.form.id.toUpperCase();
        this.form.birth = String(parseInt(this.form.birth));
        ls.set("form", this.form, expired);

        try {
          let mapVaccines = vaccines.map((o) => o.key);
          let { data } = await axios.post(`/influenza/qualify`, {
            ...this.form,
            vaccines: mapVaccines,
          });
          // 如果同時有 PCV 和 PPV，則僅留 PCV
          if (data && data.preCheck["13PCV"] && data.preCheck["PPV"]) {
            data.preCheck["PPV"] = false;
          }
          // console.log(data);

          this.$store.commit("setPerson", data);
          this.$router.push({
            name: "InfluenzaMyReservation",
            params: {
              toReserve: action == "reserve",
            },
          });
        } catch (error) {
          this.$toast.error(error.response.data);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-subtitle-1 {
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 10px;
  color: #c75213;
}
ol > li {
  font-size: 16px;
  // font-weight: bold;
  line-height: 26px;
  // color: #c75213;
}
ul > li {
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.6);
}
.link {
  color: #c75213;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .v-text-field input {
  text-align: left;
}
</style>
